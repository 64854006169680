<template>
  <div style="padding-top:100px">
    <b-row v-if="data.template === 'icon_top_text_bottom'"  style="text-align:center" no-gutters>
      <b-col cols="12">
        <b-img
          :src="data.icon_url"
          width="80px"
          height="80px"
        />
      </b-col>
      <b-col cols="12">
        <div class="content-template">
          <div class="template">
            {{ data.message_template }}
          </div>
          <div class="description">
            {{ data.description }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="data.template === 'icon_left_text_right'"  style="text-align:center" no-gutters>
      <b-col cols="3">
        <b-img
          style="float:right"
          :src="data.icon_url"
          width="80px"
          height="80px"
        />
      </b-col>
      <b-col cols="9">
        <div class="content-template" style="float:left">
          <div class="template">
            {{ data.message_template }}
          </div>
          <div class="description">
            {{ data.description }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="data.template === 'icon_right_text_left'"  style="text-align:center" no-gutters>
      <b-col cols="9">
        <div class="content-template" style="float:right">
          <div class="template">
            {{ data.message_template }}
          </div>
          <div class="description">
            {{ data.description }}
          </div>
        </div>
      </b-col>
      <b-col cols="3">
        <b-img
          style="float:left"
          :src="data.icon_url"
          width="80px"
          height="80px"
        />
      </b-col>
    </b-row>
    <b-row v-if="data.template === 'icon_bottom_text_top'"  style="text-align:center" no-gutters>
      <b-col cols="12">
        <div class="content-template">
          <div class="template">
            {{ data.message_template }}
          </div>
          <div class="description">
            {{ data.description }}
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <b-img
          :src="data.icon_url"
          width="80px"
          height="80px"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { BRow, BCol, BImg } from 'bootstrap-vue'

import io from 'socket.io-client'

export default {
  created() {
    this.socket = io(process.env.VUE_APP_SOCKET_SERVE)
    this.$http.get(`/widgets/token/${this.$route.params.id}`).then(({ data }) => {
      console.log('data', data)
      // this.data = data.json
    })
      .catch(() => {
        this.$router.push({ name: 'error-404' })
      })
  },
  methods: {
    showTemplate(template) {
      this.data.template = template
    },
    doneAudio(timeOut) {
      console.log('timeOut doneAudio', timeOut)
      setTimeout(() => {
        this.showTemplate(0)
      }, timeOut)
    },
    endedAudio(audio) {
      if (audio) {
        audio.addEventListener('ended', () => {
          let ring = new Audio(this.data.description_audio_url)
          const playPromise = ring.play()
          playPromise.then(() => {
            ring.play()
            ring.addEventListener('ended', () => {
              console.log('done audio gg')
              this.doneAudio(5000)
            })
          }).catch(() => {
            console.log('catch audio gg')
            ring.play()
            this.doneAudio(10000)
          })
        })
      } else {
        this.doneAudio(10000)
      }
    },
  },
  components: {
    BImg,
    BRow,
    BCol,
  },
  mounted() {
    this.socket.emit('join room', {
      id: this.$route.params.id,
    })

    this.socket.on('new_comment', data => {
      console.log('new_comment', data.data)
      this.data = data.data
      this.showTemplate(this.data.template)
      let ring = new Audio(this.data.sound_url)
      const playPromise = ring.play()
      playPromise.then(() => {
        ring.play()
        this.endedAudio(ring)
      }).catch(() => {
        ring.play()
        this.endedAudio()
      })
    })
  },
  data() {
    return {
      data: {
        // eslint-disable-next-line global-require
        template: 0,
        icon_url: require('@/assets/images/top/1.png'),
        message_template: 'Người donate đã tặng cho bạn!',
        description: 'xin chào các bạn ơi các bạn cần gì!',
        sound_url: 'https://storage-singapore.myepis.cloud/5/1634814716/nhac_donate_viruss_721799964787315393.mp3',
        google_speech: 'https://chunk.lab.zalo.ai/905e294ee6170f495606/905e294ee6170f495606',
      },
    }
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=Josefin+Sans&display=swap');
html[dir] body{
  background: none !important;
}
.template {
  font-family: 'Calistoga', cursive;
  color: red;
  font-weight: bold;
  font-size: 30px;
  padding-left: 10px;
  text-shadow: 0 0 13px #110e0e;
}
.container {
  text-align: center;
  padding-top:100px;
}

.description {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 25px;
  color: rgb(252, 252, 252);
  text-shadow: 0 0 13px #cc0000;
  font-weight: bold;
}

.img {
  float: left;
  position: absolute;
  width: 50%;
}
.imgRight {
  right: 0;
}
</style>
